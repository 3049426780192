import {
  BookOpenLine,
  CutleryLine,
  DollarLine,
  DumbellLine,
  Home2Line,
  SunLine,
} from '@zealy/icons';

export const ASSETS_SLIDER = [
  '/nstatic/careers/0.webp',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/users/9560deff-9d88-4ce4-b892-3595ba354f25/2543550b-f470-46fc-9994-7791d5b6bbbc-IMG_0382-COMPRESS1.mp4',
  '/nstatic/careers/1.webp',
  '/nstatic/careers/felicita.webp',
  '/nstatic/careers/11.webp',
  '/nstatic/careers/3.webp',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/users/9560deff-9d88-4ce4-b892-3595ba354f25/3c8a07f8-4625-477a-a21c-d8a19182c30f-IMG_0624-COMPRESS1.mp4',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/users/9560deff-9d88-4ce4-b892-3595ba354f25/73ea7e71-8ace-464a-83a4-1dc45a703528-IMG_0660-COMPRESS.mp4',
  '/nstatic/careers/4.webp',
  '/nstatic/careers/2.webp',
  '/nstatic/careers/stationf.webp',
  '/nstatic/careers/10.webp',
  '/nstatic/careers/8.webp',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/users/9560deff-9d88-4ce4-b892-3595ba354f25/916e0873-5460-4ae8-b384-7c1e29274353-IMG_0445-COMPRESS.mp4',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/3f6e9fe3-2543-4c78-950c-66f64f68025c-49398B9D-FABB-4F07-82AE-825D55AE2ACF_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/f50e3528-3f32-4be8-a49d-dbb3853d71a0-E6D86F63-C04A-4DE2-A64E-A7B14669A0D8_1_201_a.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/97739b2d-5fb9-4a49-bb3b-14ff8f58f116-IMG_1497.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/d73199f5-4c75-46f7-83fa-c4b74fbb2619-0327D60D-7C06-4FD5-956B-3149A2FF7B3A_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/13477465-e450-4603-9c18-19549ae93385-2EC9622A-375D-4A17-A673-2E32CCE3CE3F_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/2f172fac-2fbb-43a3-8288-7c4927d3b953-9B14F9A2-5B3E-45FE-84B7-9C7015A323DB.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/7a33bc57-c82f-4210-bfe6-2c80fb12faf7-96D26883-5EF0-49BD-855B-B40C83139643_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/dfd08d03-57ff-4989-a8b7-ba135a21939f-F51F750C-6BB7-40A1-A831-476FB7D38A13_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/users/9560deff-9d88-4ce4-b892-3595ba354f25/e3900edd-1740-4c6c-989f-5e7382998114-IMG_7221-REMOVE-AUDIO-COMPRESS.mp4',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/dada976f-756d-4653-a91f-80ad61631009-5060F480-FE80-453C-881F-ECC1ED07D932_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/86d33b6d-3791-4bdc-b0f1-daebd91527b8-4FD6901A-4A01-408D-89F6-03C893633C7E_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/75905761-0d2d-400b-861f-bb9c33ba914f-05540A83-736C-4A51-ABD8-C2B97B49A877_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/communities/68c26216-2068-453d-8596-501b7e776659/4f42ef56-2200-403e-877c-19af243a370e-161D14B0-55F6-459B-84E6-FE58E9C9DFD9_1_105_c.jpeg',
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/users/c9d2df51-81b6-43e7-9e86-4c52c278cf5c/8c4bb99d-a891-4701-b03f-caad93bf4280-IMG1040video.mp4',
] as const;

export const BASE_URL = 'https://jobs.ashbyhq.com/zealy';

export const JOBS = [
  {
    title: 'Growth',
    section: 'Business',
    link: `${BASE_URL}/e83c4976-cd3b-4452-b219-6bb571882fe9`,
    type: 'permanent',
  },
  {
    title: 'Senior Product Designer',
    section: 'Product',
    link: `${BASE_URL}/1ddff2f3-6531-4b87-94c6-e2e66bd3eb34`,
    type: 'permanent',
  },
  {
    title: 'Chief of Staff',
    section: 'Operations',
    link: `${BASE_URL}/a3ec62ab-e0ff-44cb-81f9-29aa0c2dee03`,
    type: 'permanent',
  },
];

export const PERKS = [
  {
    title: 'compensation',
    icon: <DollarLine size={20} />,
  },
  {
    title: 'vacation',
    icon: <SunLine size={20} />,
  },
  {
    title: 'meal',
    icon: <CutleryLine size={20} />,
  },
  {
    title: 'remote',
    icon: <Home2Line size={20} />,
  },
  {
    title: 'learning',
    icon: <BookOpenLine size={20} />,
  },
  {
    title: 'gym',
    icon: <DumbellLine size={20} />,
  },
] as const;
